<template>
  <div>
    <div style="padding-bottom: 20px;">
      <div style="height: 60px;">
        <div class="top-title" style="position: fixed;z-index: 201;">
          <div class="middle-size" style="display: flex;flex-direction: row;position: fixed;background-color: #fafcfd;padding: 10px 0 5px;">
            <div class="game-detail-back">
              <img src="../../../static/icon/back.png" @click="$router.back()">
            </div>
            <div style="margin-left: 10px;">
              游戏礼包
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="background-color: #f0f2f4;border-radius: 8px;padding: 10px;font-size: 13px;display: flex;justify-content: space-between;">
          <span v-if="Object.keys(selectRole).length !== 0">
            当前角色：{{ selectRole.serverName + ' / ' + selectRole.roleName }}
          </span>
          <span style="color: #1cd4b8" @click="onSelectRole">[选择角色]</span>
        </div>
        <van-collapse @change="onChangeCollapse" v-model="activeName" accordion v-if="giftList.length !== 0">
          <van-collapse-item :ref="'collapseItem' + index" :name="index + ''" v-for="(item,index) in giftList" :key="item.giftId">
            <template #title>
              <div>
                <span style="font-size: 17px;">{{item.giftName}}</span>
                <div v-if="item.giftType !== '3'" :style="{color: formatterColor(item.percentage)}">剩余{{ item.percentage }}%</div>
              </div>
            </template>
            <template #value>
              <div style="margin-right: 10px;">
                <button @click.stop="onClick(item.giftId)" class="get-gift" v-if="item.giftType !== '3' && item.status === 0">领取</button>
                <button @click.stop="copyCode(item.giftCode)" v-if="item.status === 1" class="copy-btn">复制</button>
              </div>
            </template>
            <div>
              <div style="display: flex;flex-direction: column;">
                <div>
                  <div class="gift__content-view-title">礼包内容</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ item.giftDetail || '暂无礼包内容'}}
                  </div>
                </div>
                <div>
                  <div class="gift__content-view-title">领取限制</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ formatterLimit(item.giftLimit) || '暂无'}}
                  </div>
                </div>
                <div>
                  <div class="gift__content-view-title">礼包描述</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ item.giftDesc || '暂无描述'}}
                  </div>
                </div>
                <div>
                  <div class="gift__content-view-title">截止日期</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ item.endTime || '暂无截至日期'}}
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div v-else>
          <div>
            <img src="../../../static/icon/empty.png" width="350" height="300" style="margin: 0 auto;display: block;"/>
          </div>
          <div class="no-gift-text">
            暂无礼包
          </div>
        </div>
      </div>

      <van-dialog v-model="selectDialog" showCancelButton confirm-button-color="#1cd4b8" zIndex="2000" @confirm="onConfirm">
        <div class="gift-select-main">
          <el-form v-model="form" label-position="top" label-width="80px" hide-required-asterisk>
            <el-form-item label="账号选择" prop="account">
              <el-select v-model="form.account" placeholder="请选择账号" size="small" :popper-append-to-body="false" @change="onAccountChange">
                <el-option v-for="item in accountList" :key="item.account_id" :label="item.account_name" :value="item.account_id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="角色选择" prop="role">
              <el-select v-model="form.role" placeholder="请选择角色" size="small" :popper-append-to-body="false" @change="onRoleChange">
                <el-option v-for="item in selectRoleList" :key="item.roleId" :label="item.serverName + ' / ' + item.roleName" :value="item.roleId"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </van-dialog>

      <myloading v-show="showLoading"></myloading>
    </div>
  </div>
</template>

<script>
import {getGiftStatus, queryGift, receiveGift, request} from "@/api/request";
import {Toast} from "vant";
import Myloading from "@/components/myloading.vue";

export default {
  name: "giftlist",
  components: {Myloading},
  data(){
    return {
      activeName: "0",
      gameId: "",
      giftList: [],
      selectRole: {},
      selectDialog: false,
      userId: '',
      accountList: [],
      roleList: [],
      selectRoleList: [],
      form: {
        account: '',
        role: '',
      },
      giftId: '',
      showLoading: false,
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    if(localStorage.getItem("user_info")) {
      this.userId = JSON.parse(localStorage.getItem("user_info")).userId;
    }
    this.loadGift();
  },
  methods: {
    loadGift(){
      let params = {
        gameId: this.gameId,
        userId: this.userId
      }
      queryGift(params).then(res => {
        if(res.data.success){
          this.giftList = res.data.data.giftList;
          if(this.giftList.length !== 0){
            this.onChangeCollapse(this.activeName);
          }
          
          if(res.data.data.userAccountList) {
            this.accountList = res.data.data.userAccountList.accountList;
          }
          this.roleList = res.data.data.gameRoles;

          this.check();
        }
      })
    },
    receiveGift() {
      if(!this.form.account) {
        this.$toast('请选择账号');
        return;
      }
      if(!this.form.role) {
        this.$toast('请选择角色');
        return;
      }
      
      this.$toast.loading({
        message: '正在领取中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })

      let params = {
        accountId: this.selectRole.accountId,
        roleId: this.selectRole.roleId,
        roleCode: this.selectRole.roleCode,
        giftId: this.giftId
      }
      receiveGift(params).then(res => {
        if(res.data.success){
          this.copyCode(res.data.data.giftCode);
          this.loadGift();
        }else {
          this.$toast.fail(res.data.error.message);
        }
      }).finally(() => {
        this.$toast.clear();
      })
    },
    refreshGiftList() {
      this.showLoading = true;
      let params = {
        gameId: this.gameId,
        roleCode: this.selectRole.roleCode,
        roleId: this.selectRole.roleId,
        accountId: this.selectRole.accountId
      };
      getGiftStatus(params).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          let d = res.data.data;
          this.giftList.forEach(function(e,v) {
            for(let i = 0; i < d.length ; i++) {
              if (e.giftId === d[i].giftId) {
                e.status = d[i].status;
                e.giftCode = d[i].giftCode;
                break;
              }
            }
          });
        } else {
          Toast(res.data.error.message);
        }
      });
    },
    check() {
      const token = localStorage.getItem("web_token");
      if(!token){
        this.$parent.openMenu();
        this.getLoginStatus();
        return;
      }
      let selectRole = JSON.parse(sessionStorage.getItem("selectRole"));
      if(selectRole) {
        this.form.account = parseInt(selectRole.accountId);
        this.form.role = selectRole.roleId;
        this.onAccountChange(parseInt(selectRole.accountId));
        this.selectRole = selectRole;
        this.refreshGiftList();
      }else {
        this.openSelectRole();
      }
    },
    onSelectRole() {
      const token = localStorage.getItem("web_token");
      if(!token){
        this.$parent.openMenu();
        return;
      }
      this.openSelectRole();
    },
    onClick(giftId){
      this.giftId = giftId;
      this.receiveGift();
    },
    openSelectRole() {
      this.selectDialog = true;
    },
    closeSelectRole() {
      this.selectDialog = false;
    },
    onConfirm(value) {
      if(!this.form.account) {
        this.$toast('请选择账号');
        return;
      }
      if(!this.form.role) {
        this.$toast('请选择角色');
        return;
      }
      sessionStorage.setItem("selectRole", JSON.stringify(this.selectRole));
      this.refreshGiftList();
    },
    onAccountChange(value) {
      let item = this.roleList.filter(f => value === parseInt(f.accountId));
      this.selectRoleList = [];
      this.selectRoleList = item;
    },
    onRoleChange(value) {
      let find = this.selectRoleList.find(f => value === f.roleId);
      this.selectRole = null;
      this.selectRole = find;
    },
    copyCode(v) {
      this.$dialog.alert({
        title: '礼包码',
        message: '你的礼包码为' + v,
        showCancelButton: false,
        confirmButtonText: '复制'
      }).then(() => {
        const input = document.createElement('input')
        document.body.appendChild(input)
        input.setAttribute('value', v)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        document.body.removeChild(input)
      })
    },
    onChangeCollapse(activeName){
      if(!activeName){
        this.setCollapseItem(this.activeName,"8px");
        return;
      }
      if(this.activeName){
        this.setCollapseItem(this.activeName,"8px");
      }
      this.setCollapseItem(activeName,"0");
    },
    setCollapseItem(activeName,px){
      try {
        let _ref = this.$refs["collapseItem" + activeName][0];
        _ref.$el.children[0].style.borderBottomLeftRadius = px;
        _ref.$el.children[0].style.borderBottomRightRadius = px;
      }catch (err){}
    },
    getLoginStatus() {
      let timer = setInterval(() => {
        let token = localStorage.getItem("web_token");
        if(token) {
          this.$router.go(0);
          clearInterval(timer);
        }
      }, 500)
    },
    formatterColor(percentage){
      let color = "";
      if(percentage < 30){
        color = "#F56C6C";
      }else if(percentage < 70){
        color = "#E6A23C";
      }else {
        color = "#67C23A";
      }
      return color;
    },
    formatterLimit(limit){
      let rep = "";
      if(limit === "1"){
        rep = "每个账号限领取一次";
      }else if(limit === "2"){
        rep = "每个角色限领取一次";
      }
      return rep;
    }
  }
}
</script>

<style scoped src="../../../static/css/game-detail.css" />
<style scoped src="../../../static/css/gift.css" />
<style scoped>
::v-deep .van-cell {
  background-color: #f0f2f4;
  height: 80px;
  align-items: center;
  margin-top: 10px;
  border-radius: 8px;
}
::v-deep .van-collapse-item__content {
  background-color: #f0f2f4;
}
::v-deep .van-collapse-item__wrapper {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-x_15 {
  padding: 0 15px;
}
.gift__content-view-title {
  color: #252525;
  margin-top: 10px;
}
.gift__content-view-main:first-child {
  margin-top: 0;
}
.gift__content-view-main {
  color: #75787b;
}
.no-gift-text {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.gift-select-main {
  padding: 15px;
  min-height: 210px;
}
.gift-select-main .el-select {
  width: 100%;
}
.gift-select-main .el-form-item {
  margin-bottom: 10px;
}
::v-deep .gift-select-main .el-form-item__label {
  padding: 0;
}
::v-deep .gift-select-main .el-select .el-input.is-focus .el-input__inner,
::v-deep .gift-select-main .el-select .el-input__inner:focus {
  border-color: #1cd4b8;
}

::v-deep .gift-select-main .el-select-dropdown__item.selected {
  color: #1cd4b8;
}

::v-deep .gift-select-main .el-popper {
  left: unset !important;
  top: unset !important;
}

</style>