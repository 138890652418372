<template>
  <div class="sdk-page">
    <div class="sdk-nav-bar">
      <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
        <van-icon name="arrow-left" size="20" color="#888888"/>
      </div>
      <div style="margin-left:40px;font-size: 16px;">
        礼包领取
      </div>
      <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
    </div>
    <div style="margin: 10px 20px;height: calc(100vh - 60px);overflow: hidden;overflow-y: auto;">
      <div style="display: flex;flex-direction: column;">
        <div class="game-info-view" style="height: 60px;">
          <img :src="imgUrl" width="40" height="40"/>
          <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-around;margin-left: 10px">
            <span style="margin-left: 5px;font-size: 15px;">{{ gameName }}</span>
            <div style="font-size: 14px;">
              <span class="game-tag" v-for="tag in tagList">{{tag}}</span>
            </div>
          </div>
        </div>
        
        <div class="game-info-view" style="margin-top: 10px;font-size: 14px;flex-direction: column;align-items: flex-start;padding: 6px 8px;">
          <div>我的角色</div>
          <div class="no-item-view" v-if="rechargeInfoList.length === 0">
            <span class="none-text" >暂无充值记录</span>
          </div>
          <div v-for="(item, index) in rechargeInfoList" style="width: 100%;">
            <div class="role-view" @click="openRecharge(index)">
              <div style="display: flex;align-items: center">
                <span style="color: #252525;font-size: 12px;" :style="item.selected ? 'font-weight: 700;color: #9bd2ac;' : ''">{{item.serverName}} / {{item.roleName}}</span>
              </div>
              <div style="display: flex;align-items: center">
                <span  @click.stop="setRole(index)" v-show="!item.selected" style="font-size: 12px;padding: 2px 5px; color: #48c9b0;border: 1px solid #48c9b0;border-radius: 2px;">选择</span>
                <span  v-show="item.selected" style="font-size: 12px;color: #9bd2ac;font-weight: bold">已选择</span>
                <van-icon v-show="!item.open" name="arrow-down" color="#888888" style="margin-left: 15px;"/>
                <van-icon v-show="item.open" name="arrow-up" color="#888888" style="margin-left: 15px;"/>
              </div>
            </div>
            <div v-show="item.open">
              <div  class="recharge-info">
                <span>累计充值</span>
                <span style="font-weight: bold">￥{{item.allAmount}}</span>
              </div>
              <div v-for="day in item.roleRechargeListList" class="recharge-info">
                <span>{{day.rechargeDate}}</span>
                <span style="font-weight: bold">￥{{day.rechargeAmount}}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="game-info-view" @click="toGiftRecord" style="height: 40px;margin-top:10px;justify-content: space-between;font-size: 13px;">
          <div>礼包领取记录</div>
          <van-icon name="arrow" color="#888888" />
        </div>

        <div style="margin-top: 10px;">
          <div v-if="list.length !== 0" style="display: flex;flex-direction: column;">
            <div class="game-menu" v-for="(item,index) in list" style="min-height: 50px;">
              <div @click="open(index)" style="display: flex;align-items: center;justify-content: space-between;padding: 6px 0;width: 90%;margin: 0 auto;font-size: 12px;">
                <div style="flex: 1;">
                  <span style="font-size: 12px;font-weight: bold;">{{item.giftName}}</span>
                  <div v-if="item.giftType !== '3'" :style="{color: formatterColor(item.percentage)}" style="text-align: left;padding: 0 0 6px;width: 90%;font-size: 12px;">
                    <div>
                      <span>剩余：{{ item.percentage }}%</span>
                    </div>
                  </div>
                </div>
                
                <div style="display: flex;align-items: center">
                    <van-button v-if="item.status === -1" size="mini" round color="#d5d5d5" class="get-button">
                        <span class="get-button-text">查看说明</span>
                    </van-button>
                  <van-button v-if="item.status === 0 && (item.percentage && item.percentage !== 0)"
                              @click.stop="getGift(item.giftId)" size="mini" round color="#3DA7FF" class="get-button">
                    <span class="get-button-text">领取</span>
                  </van-button>
                    <van-button v-if="item.status === 1"
                                @click.stop="copyGiftCode(item.giftCode)" size="mini" round color="#e9b566" class="get-button">
                        <span class="get-button-text">复制</span>
                    </van-button>
                  <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                  <van-icon v-show="item.open" name="arrow-up" color="#888888" />
                </div>
              </div>
              <div v-show="item.open" style="width: 85%;margin: 10px auto;text-align: left;font-size: 12px;color: #666666">
                <div class="game-detail-info">
                  <span style="color: #48c9b0;font-size: 13px;">礼包内容</span>
                </div>
                <span style="margin: 20px 10px 0;display: block">
                  {{item.giftDetail || '无'}}
                </span>
                <div class="game-detail-info" style="margin-top: 15px;">
                  <span style="color: #48c9b0;font-size: 13px;">领取限制</span>
                </div>
                <span style="margin: 20px 10px 0;display: block">
                  {{formatterLimit(item.giftLimit) || '无'}}
                </span>
                <div class="game-detail-info" style="margin-top: 15px;">
                  <span style="color: #48c9b0;font-size: 13px;">礼包说明</span>
                </div>
                <span style="margin: 20px 10px 0;display: block;">
                  {{item.giftDesc || '无'}}
                </span>

<!--                <div style="margin-top: 15px;" v-if="item.giftType === '3'">-->
<!--                  <span style="color: #3DA7FF;font-size: 13px;">点击联系客服</span>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div v-else>
            <div style="text-align: center;margin-top: 20px;color: #888888;font-size: 13px;">
              暂无礼包
            </div>
          </div>
        </div>

        <myloading v-show="showLoading"></myloading>
      </div>
    </div>
  </div>
</template>

<script>
import sdkNotice from "@/api/sdk-notice";
import {receiveGift, request, sdkReceiveGift} from "@/api/request";
import Myloading from "@/components/myloading.vue";
import {Toast} from "vant";

export default {
  name: "SdkGift",
  components: {Myloading},
  data(){
    return {
      showLoading: false,
      list: [],
      imgUrl: '',
      gameName: '',
      tagList: [],
      rechargeInfoList: [],
      selectDateList: [],
      selectedDate: '未选择',
      selectedIndex: -1,
      selectRole: {},
      selectedGiftId: '',
      gameId: '',
    }
  },
  mounted() {
    this.gameId = localStorage.getItem("gameId");
    this.getData();
  },
  methods: {
    back() {
      this.$router.back()
    },
    close(){
      sdkNotice.close();
    },
    getData() {
      this.showLoading = true;
      let options = {
        url:"/sdk/h5/getGameGiftList",
        data: {
          gameId: this.gameId
        }
      };
      request(options).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          let d = res.data.data;
          this.imgUrl = d.gameImgUrl;
          this.gameName = d.gameName;
          this.tagList = d.tagList;
          
          let me = this;
          let select_role = JSON.parse(localStorage.getItem("select_role"));
          
          d.giftList.forEach(function(e,v) {
            e.open = false;
          });
          this.list = d.giftList;
          
          if(d.rechargeInfoList.length > 0) {
            d.rechargeInfoList.forEach(function(e,v) {
              e.open = false;
              e.selected = false;
              if(select_role && e.roleId === select_role.roleId){
                me.selectRole = select_role;
                e.selected = true;
              }
            });
            this.rechargeInfoList = d.rechargeInfoList;
            if(!select_role) {
              this.setRole(0);
            }else {
              let index = d.rechargeInfoList.findIndex(f => f.roleId === select_role.roleId);
              if(index !== -1) {
                this.setRole(index);
              }
            }
          }
        }
        else {
          this.$toast(res.data.error.message);
        }
      });
    },
    getGift(giftId){
      let params = {
        giftId: giftId,
        roleCode: this.selectRole.orderRoleCode,
      }
      sdkReceiveGift(params).then(res => {
        if(res.data.success){
          let code = res.data.data.giftCode;
          this.$dialog.alert({
            title: '礼包码',
            message: '你的礼包码为' + code,
            showCancelButton: false,
            confirmButtonText: '复制'
          }).then(() => {
            sdkNotice.copy(code);
            this.getData();
          })
        }else {
          this.$toast.fail(res.data.error.message);
        }
      })
    },
    toGiftRecord(){
      this.$router.push("/sdk/giftRecord")
    },
    open(index) {
      this.list[index].open = !this.list[index].open;
    },
    copyCode(v) {
      this.$dialog.alert({
        title: '礼包码',
        message: '你的礼包码为' + v,
        showCancelButton: false,
        confirmButtonText: '复制'
      }).then(() => {
        const input = document.createElement('input')
        document.body.appendChild(input)
        input.setAttribute('value', v)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        document.body.removeChild(input)
      })
    },
    copyGiftCode(v) {
      sdkNotice.copy(v);
      this.$toast("复制成功");
    },
    formatterColor(percentage){
      let color = "";
      if(percentage < 30){
        color = "#F56C6C";
      }else if(percentage < 70){
        color = "#E6A23C";
      }else {
        color = "#67C23A";
      }
      return color;
    },
    formatterLimit(limit){
      let rep = "";
      if(limit === "1"){
        rep = "一个账号只能领取一次";
      }else if(limit === "2"){
        rep = "一个角色只能领取一次";
      }
      return rep;
    },
    openRecharge(index){
      this.rechargeInfoList[index].open = !this.rechargeInfoList[index].open;
    },
    setRole(index) {
      this.rechargeInfoList.forEach(function(e,v) {
        e.selected = false;
      });
      this.selectRole = this.rechargeInfoList[index];
      this.rechargeInfoList[index].selected = !this.rechargeInfoList[index].selected;

      this.selectDateList = [];
      let me = this;
      this.rechargeInfoList[index].roleRechargeListList.forEach(function(e,v) {
        me.selectDateList.push(e.rechargeDate);
      });
      this.selectedIndex = -1;
      this.selectedDate = '未选择';

      localStorage.setItem('select_role', JSON.stringify(this.selectRole));

      this.refreshGiftList();
    },
    refreshGiftList(title) {
      this.showLoading = true;
      let date_ = this.selectedDate === '未选择' ? '' : this.selectedDate;
      let options = {
        url:"/sdk/h5/getGiftStatus",
        data: {
          gameId: this.gameId,
          roleCode: this.selectRole.orderRoleCode,
          roleId: this.selectRole.roleId,
          giftDate: date_,
        }
      };
      request(options).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          let d = res.data.data;
          this.list.forEach(function(e,v) {
            for(let i = 0; i < d.length ; i++) {
              if (e.giftId === d[i].giftId) {
                e.status = d[i].status;
                e.giftCode = d[i].giftCode;
                break;
              }
            }
          });
          if (title) {
            Toast(title);
          }
        }
        else {
          Toast(res.data.error.message);
        }
      });
    },
  }
}
</script>

<style scoped>
.game-info-view {
  display: flex;
  align-items: center;
  padding:0 10px;
  background-color: #f0f2f4;
  border-radius: 5px;
}
.get-button {
  padding: 0 8px;
  margin-right: 15px;
}
.game-menu{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #f0f2f4;
  border-radius: 4px;
  margin-top: 6px;
  align-items: flex-start;
}
.game-menu:first-child {
  margin-top: 0;
}
.game-detail-info{
  /*background-image: url(../../img/gift/title_background.png);*/
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 60px;
  height: 10px;
  padding-top: 5px;
  text-align: center;
}
.game-tag {
  font-size: 10px;
  border-radius: 3px;
  margin-right: 5px;
  /*border: 1px solid #e6a23c;*/
  padding: 2px 3px;
  color: #e6a23c;
}

.no-item-view{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}
.none-text{
  font-size: 14px;
  color: #aaaaaa;
  text-align: center;
}
.role-view{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  width: 90%;
  margin: 0 auto;
}
.recharge-info {
  font-size: 11px;
  color: #999999;
  padding: 0 15px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>